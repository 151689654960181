import React from 'react';
import {Helmet} from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/Footer"
import p05pollito01Audio from "../audio/p05pollito01Audio.m4a"
import p05pollito02Audio from "../audio/p05pollito02Audio.m4a"
import p05pollito03Audio from "../audio/p05pollito03Audio.m4a"
import p05GallinaAudio from "../audio/p05GallinaAudio.m4a"
import p05huevosAudio from "../audio/p05huevosAudio.m4a"
import p05VO from "../audio/p05VO.m4a"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page05 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p05_cencerro.png"],
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
    if(x===4){
      if(!this.state.active){
        audioEl.play()
      }
      else{ audioEl.pause();audioEl.currentTime = 0; }
    }
    else{ audioEl.play() }
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBoton.png"]})}, 1000 )
      }
      else { this.setState({cencerro: images["p05_cencerro.png"]}) }
    })
}
  render() {
    return (
      <div className="main-container">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@700&display=swap" rel="stylesheet" />
      </Helmet>
      <Link to="/Page04">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page06">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p05_back.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p05Cuentamelo" onClick={() => this.playAudio(5)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className="_idContainer050">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_gallina.png"]} alt="" />
          </div>
          <div className={"_idContainer051" + (this.state.active ? " active" : "") }>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_huevo06.png"]} />
          </div>
          <div className={"_idContainer052" + (this.state.active ? " active" : "") }>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_huevo05.png"]} alt="" />
          </div>
          <div className={"_idContainer053" + (this.state.active ? " active" : "") }>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_huevo04.png"]} alt="" />
          </div>
          <div className={"_idContainer054" + (this.state.active ? " active" : "") }>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_huevo03.png"]} alt="" />
          </div>
          <div className={"_idContainer055" + (this.state.active ? " active" : "") }>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_huevo02.png"]} alt="" />
          </div>
          <div className={"_idContainer056" + (this.state.active ? " active" : "") }>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_huevo01.png"]} alt="" />
          </div>
          <div className="_idContainer057">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_pollito03.png"]} alt="" />
          </div>
          <div className="_idContainer058">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_pollito03Ala.png"]} alt="" />
          </div>
          <div className="_idContainer059">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_pollito02.png"]} alt="" />
          </div>
          <div className="_idContainer060">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_pollito02Ala.png"]} alt="" />
          </div>
          <div className="_idContainer061">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_pollito01.png"]} alt="" />
          </div>
          <div className="_idContainer062">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p05_pollito01Ala.png"]} alt="" />
          </div>
          <div className="_idContainer063 Basic-Text-Frame">
            <span>La gallina ponía huevos de oro.</span>
          </div>
          <div className="_idContainer064" onClick={() => this.playAudio(0)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4253.png"]} alt="" />
          </div>
          <div className="_idContainer065" onClick={() => this.playAudio(1)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4252.png"]} alt="" />
          </div>
          <div className="_idContainer066" onClick={() => this.playAudio(2)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4254.png"]} alt="" />
          </div>
          <div className="_idContainer067" onClick={() => this.playAudio(3)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4255.png"]} alt="" />
          </div>
          <div className={"_idContainer068" + (this.state.active ? " active" : "")}
            onClick={() => {this.handleCencerroClick(); this.playAudio(4)}} >
            <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
          </div>
          <audio className="audio-element">
            <source src={p05pollito01Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p05pollito02Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p05pollito03Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p05GallinaAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p05huevosAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p05VO}></source>
          </audio>
        </div>
      <Footer />
      </div>
    )
  }
  }

export default Page05;
